
























import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    PaypalPaymentsRenovar: () => import("./Paypal-payment-renovar.vue"),
  },
})
export default class Paypal_payment_decorator extends Vue {}
